html, body
	overflow: auto !important

.app-promo-container
	display: flex
	flex-direction: column
	align-items: center
	justify-content: space-between
	padding: 10px
	padding-block-end: 120px
	min-height: 100vh
	overflow-y: auto

.app-promo-card
	max-width: 550px
	width: 100%
	text-align: center

.app-logo
	width: 200px

.app-subtitle
	font-size: 20px
	font-weight: 500
	color: #061e1e
	margin-bottom: 20px

.app-carousel img
	max-width: 100%
	max-height: 550px
	object-fit: contain

@media (min-width: 1700px)
	.app-carousel img
		max-height: 850px

@media (min-width: 1920px)
	.app-carousel img
		max-height: 1050px

.footer
	margin-top: 40px
	padding-block: 10px
	width: 100%
	background-color: #061e1e
	text-align: center
	position: fixed
	bottom: 0
	left: 0
	z-index: 1000
	box-shadow: 0 -2px 5px rgba(255, 255, 255, 0.1)

.footer .store-buttons
	display: flex
	justify-content: center
	align-items: center
	padding-block-end: 10px

.footer .store-buttons .store-button img
	width: 150px
	padding-inline: 5px
	height: 46.8px

.footer-links
	display: flex
	justify-content: center
	gap: 20px

.footer-link
	color: #fff
	text-decoration: none
	font-size: 14px

.footer-link:hover
	color: #00b4b5
	text-decoration: underline

@media (max-width: 600px)
	.app-logo
		width: 200px
	.app-subtitle
		font-size: 16px
	.app-carousel img
		max-height: 450px
	.store-button img
		width: 130px
